import { useRouter, type NavigateOptions } from '@tanstack/react-router'
import { useCallback } from 'react'

export type BuildUrl = (options: NavigateOptions) => string

export function useBuildUrl(): { buildUrl: BuildUrl } {
  const router = useRouter()

  const buildUrl: BuildUrl = useCallback<BuildUrl>(
    (options) => {
      const location = router.buildLocation(options)
      const url = new URL(location.href, document.location.origin)
      return url.href
    },
    [router],
  )

  return {
    buildUrl,
  }
}
